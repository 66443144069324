/**
 * main.js
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */

document.addEventListener('DOMContentLoaded', donauratzenInit, false);


// Polyfill for NodeList.forEach
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}


function donauratzenInit() {
	console.log ('Ratza samma, jucka damma!');

	if (false)  {
		var button = document.createElement('button');
		button.innerHTML = 'Toggle Baseline Grid';
		button.addEventListener('click', function(ev) {
			document.body.classList.toggle('debug-base-line-grid--is-visible');
		});
		document.body.appendChild(button);
		button.style.position = 'fixed';
		button.style.zIndex = 32767;
		button.style.top = '0px';
		button.style.left = '0px';
	}

	var menuItems = document.querySelectorAll ('.main-nav ul a');
	var menuTrigger = document.querySelector('.main-nav__trigger');
	menuTrigger.addEventListener('click', function (ev) {
		ev.preventDefault ();
		ev.stopPropagation ();
		document.body.classList.toggle ('menu-is-open');
		if (document.body.classList.contains ('menu-is-open')) {
			TweenMax.staggerFromTo (menuItems, 0.1, { y: -32, opacity: 0}, { y: 0, opacity: 1, delay: 0.25 }, 0.05 );
		}
		else {
			TweenMax.staggerFromTo (menuItems, 0.1, {y: 0, opacity: 1}, { y: -32, opacity: 0}, 0.05 );
		}
		
		return false;
	});

	var headerFringes = document.querySelectorAll ('.main-header .fringe');

	
	var ctl = new ScrollMagic.Controller();
	var tl = new TimelineMax ();
	tl.staggerTo([ headerFringes[2], headerFringes[1], headerFringes[0]], 0.3, { cycle: { y: [ -50, -50, 0 ]}, ease: Linear.easeNone}, 0.1);
	// tl.to ('.main-header .fringes', 0.1, { height: '-=66px' }, .3);
	tl.to ('.logo', 0.5, { y: '-=15%', ease: Back.easeOut }, 0);
	// tl.to ('.logo img', 0.8, { y: '+=40', ease: Linear.easeNone }, 0);

	var scene = new ScrollMagic.Scene({
		triggerElement: document.body,
		offset: 80,
		triggerHook: 0,
		// duration:400
	});

	scene.setClassToggle (document.body, 'has-scrolled')
	scene.setTween(tl)
	scene.addTo(ctl);

	var tl = new TimelineMax({paused:true})
	.to ('.main-footer .fringe--tangaroa', 0.5, { backgroundPosition: '100px 0', repeat: -1, ease: Linear.easeNone }, 0)
	.to ('.main-footer .fringe--steel-blue', 0.75, { backgroundPosition: '100px 0', repeat: -1, ease: Linear.easeNone }, 0)
	.to ('.main-footer .fringe--polo-blue', 1, { backgroundPosition: '100px 0', repeat: -1, ease: Linear.easeNone }, 0)
	.timeScale(0.3);
	;

	var footer = document.querySelector('.main-footer');
	footer.addEventListener('mouseenter', function () {
		tl.play ();
	});
	footer.addEventListener('mouseleave', function () {
		tl.pause ();
	});


	// ------------------------------------------------------------------

	if (document.querySelector ('.event-filters')) {
		var events = document.querySelectorAll ('.event');
		var eventFilters = document.querySelectorAll ('.event-filter input[type=checkbox]');
		eventFilters.forEach (function(filter) {
			filter.addEventListener ('change', function () {
				onFilterChanged (this);
			});
		});
		function onFilterChanged (filter) {
			var flag = filter.checked;
			var selector = filter.getAttribute ('data-filter-selector');
			document.querySelectorAll (selector).forEach (function (el) {
				el.classList.toggle ('is-hidden', !flag);
			});
		}


		var eventsContainer = document.querySelector ('.events.main');
		var eventAccessFilters = document.querySelectorAll ('.event-filter--access input[type=radio]');

		eventAccessFilters.forEach (function(filter) {
			filter.addEventListener ('change', function () {
				onAccessFilterChanged (this);
			});
		});
		function onAccessFilterChanged (filter) {
			switch (filter.getAttribute ('data-filter-selector')) {
				case '.event--is-public':
					eventsContainer.classList.add ('show-only-public');
					eventsContainer.classList.remove ('show-only-internal');
					break;
				case '.event--is-internal':
					eventsContainer.classList.remove ('show-only-public');
					eventsContainer.classList.add ('show-only-internal');
					break;
				default:
					eventsContainer.classList.remove ('show-only-public');
					eventsContainer.classList.remove ('show-only-internal');
					break;
			}
		}
		function applyFilters () {
			// Initially check filters and apply
			eventFilters.forEach (function (filter) {
				onFilterChanged (filter);
			});
			eventAccessFilters.forEach (function (filter) {
				if (filter.checked) {
					onAccessFilterChanged (filter);
				}
			});
		}
		applyFilters ();
		// Todo: AJAX-Navigation for months
		document.querySelectorAll ('.month-select__button').forEach (initMonthButtons);
		function initMonthButtons(button) {
			button.addEventListener ('click', function (ev) {
				ev.preventDefault ();

				var xhr = new XMLHttpRequest ();
				xhr.open ('GET', this.getAttribute ('href') + '&tid=8');
				xhr.onload = function () {
					if (xhr.status >= 200 && xhr.status < 400) {
						var newContent = document.createElement('div');
						newContent.innerHTML = xhr.responseText;
						var newEvents =	newContent.querySelector ('.main.events');
						document.querySelector ('.main.events').innerHTML = newEvents.innerHTML;

						var newCalendar = newContent.querySelector ('.calendar');
						document.querySelector ('.calendar').innerHTML = newCalendar.innerHTML;

						document.querySelectorAll ('.month-select__button').forEach (initMonthButtons);
						applyFilters ();
					}
				};
				xhr.send ();
				return false;
			});
		};
	}
}

