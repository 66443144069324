document.addEventListener ('DOMContentLoaded', tabsInit, false);

function tabsInit () {
	if (!document.querySelector ('.tabs')) {
		return;
	}

	var tabPanes = document.querySelectorAll ('.tab-pane');
	var tabLinks = document.querySelectorAll ('.tab__link');

	for (var i = 0; i < tabLinks.length; i++) {
		var tabLink = tabLinks[i];
		tabLink.addEventListener ('click', function (ev) {
			console.log ('clicked');
			ev.preventDefault ();

			var target = this.getAttribute('href');
			var targetEl = document.querySelector(target);
			for (var j = 0; j < tabPanes.length; j++) {
				tabLinks[j].classList.remove ('tab__link--is-active');
				tabLinks[j].parentNode.classList.remove ('tab--is-active');
				tabPanes[j].classList.remove ('tab-pane--is-active');
			}

			targetEl.classList.add ('tab-pane--is-active');
			this.classList.add ('tab__link--is-active');
			this.parentNode.classList.add ('tab--is-active');
			
			return false;
		});
	}

	tabPanes[0].classList.add ('tab-pane--is-active');
	tabLinks[0].classList.add ('tab__link--is-active');
	tabLinks[0].parentNode.classList.remove ('tab--is-active');
};
